
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.drawTitle:global(.MuiTypography-root) {
  display: flex;
  align-items: center;
  margin: 0 16px;
  justify-content: center;
  font-size: 18px;
  line-height: 1;
}
