
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.root,
.DEBUG {
  /*!KEEP*/
}

.root {
  /* // Could be used instead of `maxWidth` prop
   * :global(.MuiDialog-paper) {
   *   min-width: 300px;
   * }
   */
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleText {
  font-size: 1.8rem;
  font-weight: normal;
}
