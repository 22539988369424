
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.appTitle {
  &:global(.MuiTypography-root) {
    font-size: 18px;
    font-weight: normal;
  }
}
