
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.nodeFoldedContent,
.nodeOwnContent,
.item,
.number,
.text,
.type,
.remark,
.root {
  /*!KEEP*/
}

.itemLabel {
  opacity: 0.5;
}

.comment {
  opacity: 0.3;
  font-style: italic;
}

.remark {
  opacity: 0.5;
}
