
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.rightContainer,
.leftContainer,
.prefix,
.title,
.icon,
.toolbar,
.root {
  /*!KEEP*/
}

.root {
  background-color: color.change(gray, $alpha: 0.2);
}

.leftContainer,
.rightContainer {
  overflow: hidden;
  // align-items: center;
  max-width: 100%;
  // width: max-content;
}
.leftContainer {
  flex: 1;
}

.wrapped {
  // border: 1px dashed gray;
  display: inline-block;
  padding: 0.5rem 0.5rem;
  &.icon,
  &.toolbar {
    opacity: 0.2;
  }
}

.prefix {
  &:global(.MuiTypography-root) {
    opacity: 0.4;
    font-weight: bold;
  }
}
