
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.root {
  border-top: 1px solid color.change(black, $alpha: 0.1);
  box-shadow: 0 -1px 3px color.change(black, $alpha: 0.1);
  z-index: 1000;
  position: relative;
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px 8px;
  :before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color.change(#777, $alpha: 0.1);
    content: '';
  }
}
.root,
.leftInfo,
.rightInfo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leftInfo,
.rightInfo {
  opacity: 0.75;
}
.leftInfo {
  flex: 1;
}
.rightInfo {
  flex-shrink: 1;
  justify-content: flex-end;
  text-align: right;
}
.DataState {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.FileInfo {
  /*!KEEP*/
}
