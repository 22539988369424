
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.ListItemIcon {
  &:global(.MuiListItemIcon-root) {
    opacity: 0.25;
    min-width: 40px;
  }
}
