
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.root {
  overflow: hidden;
  width: max-content;
  max-width: 100%;
}

$size: 1rem;
$halfSize: ($size / 2);
$smallSize: ($size / 8);

.editIcon {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  right: $smallSize;
  top: $smallSize;
  border-radius: $halfSize;
  transition: opacity $transitionTimeMs;
  background-color: $primaryColor;
  color: #fff;
  padding: 0 0.5rem;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  :global(.MuiSvgIcon-root) {
    font-size: 0.65rem;
  }
}

.clickableWrapper {
  min-width: 1.35rem;
  transition: all $transitionTimeMs;
  flex: 1;
  gap: 0.25rem;
  align-self: flex-start;
  text-align: left;
  &:global(.MuiButtonBase-root) {
    // Override MUI rules
    border-radius: 3px;
    padding: 0.5rem 0.5rem;
    justify-content: flex-start;
    border: 1px solid transparent;
  }
  .empty & {
    background-color: color.change($primaryColor, $alpha: 0.1);
    border: 1px dashed color.change($primaryColor, $alpha: 0.3);
  }
  &.buttonActive,
  &:focus-within,
  &:focus-visible,
  &:target,
  &:focus {
    background-color: color.change($primaryColor, $alpha: 0.15);
    border: 1px dashed color.change($primaryColor, $alpha: 0.5);
  }
  &:hover {
    background-color: color.change($primaryColor, $alpha: 0.3);
    border: 1px dashed color.change($primaryColor, $alpha: 0.75);
    .editIcon {
      opacity: 0.8;
    }
  }
  &:active {
    background-color: color.change($primaryColor, $alpha: 0.5);
    border: 1px dashed color.change($primaryColor, $alpha: 1);
  }
}

.textNode {
  flex: 1;
  &:global(.MuiTypography-root) {
    $height: 1.3;
    line-height: #{$height}rem;
    min-height: #{$height}rem;
  }
}

.noWrap {
  &.root,
  .clickableWrapper,
  .textNode {
    white-space: nowrap;
  }
}

.noOverflow {
  .clickableWrapper,
  .textNode {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.noShrink {
  &.root,
  .clickableWrapper,
  .textNode {
    flex-shrink: 0;
  }
}
