
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.navButtons {
  gap: 8px;
  :global(.MuiButton-startIcon) {
    opacity: 0.25;
  }
  :global(.MuiButton-root) {
    transition: opacity $animationTimeMs;
    :global(.theme-mode-light) &:hover {
      opacity: 0.7;
    }
  }
}
